/* Override styles for slick carousel  */

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slider.as-page-section .slick-track {
  align-items: flex-start;
  gap: unset;
}

.slick-slider.as-scroll .slick-track {
  gap: 1.5rem;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.slick-slide {
  padding: 0;
  z-index: -1;
}

.slick-slide.slick-active {
  z-index: 0;
}

.slick-slider.with-dots {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .slick-slider.with-arrows {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.slick-slider.as-page-section {
  padding: 0;
  margin: 0;
  margin-bottom: 5rem;
}

.slick-slider.as-page-section > .slick-dots {
  padding: 0 1.25rem;
}

@media (min-width: 768px) {
  .slick-slider.as-page-section {
    margin-bottom: 0;
  }

  .slick-slider.as-page-section .slick-dots {
    padding: 0 2.5rem;
    bottom: 3rem;
  }

  .slick-slider.as-page-section.with-arrows .slick-dots {
    padding: 0 7.5rem;
  }
}

@media (min-width: 1280px) {
  .slick-slider.as-page-section .slick-dots {
    padding: 0 5rem;
    bottom: 4rem;
  }
  .slick-slider.as-page-section.with-arrows .slick-dots {
    padding: 0 8.5rem;
  }
}

@media (min-width: 2560px) {
  .slick-slider.as-page-section .slick-dots {
    padding: 0 5rem;
    bottom: 4rem;
  }
  .slick-slider.as-page-section.with-arrows .slick-dots {
    padding: 0 12.5rem;
  }
}

.slick-slider.align-left > .slick-dots {
  text-align: left;
}

.slick-slider.align-right > .slick-dots {
  text-align: right;
}

.slick-dots {
  bottom: -3rem;
}

.slick-dots li {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.125rem;
}

.slick-dots li:first-of-type {
  margin-left: 0;
}

.slick-dots li:last-of-type {
  margin-right: 0;
}

.slick-dots li button {
  width: 0.75rem;
  height: 0.75rem;
  min-width: 0;
  border-radius: 50%;
  margin: 0;
  margin-right: 0;
  padding: 0.3125rem;
  position: absolute;
}

.slick-dots li button:before {
  content: '';
  display: none;
  width: 0.75rem;
  height: 0.75rem;
  top: -0.0625rem;
  left: -0.0625rem;
  border-radius: 50%;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  display: block;
}
