.react-datepicker {
  z-index: 2;
}

.react-datepicker__input-container input {
  background-color: transparent;
  margin-left: -1.5rem;
}

.react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
  border-color: #ff002e;
}

.react-datepicker__navigation .react-datepicker__navigation-icon::before {
  border-color: #5c5c5c;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #5c5c5c;
  border-width: 0.125rem 0.125rem 0 0;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: unset;
  border-color: #ff002e;
}

.react-datepicker__header {
  background-color: #f5f5f5;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #ebebeb;
}

.react-datepicker__day:hover {
  background: #ebebeb;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #ff002e;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #ff002e;
}

.react-datepicker__triangle {
  transform: translate3d(1rem, 0px, 0px) !important;
}

.react-datepicker__triangle::after {
  border-bottom-color: #f5f5f5 !important;
}

.react-datepicker__navigation {
  top: 0.5rem;
  text-decoration: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 0.175rem;
}

.react-datepicker__navigation--years-previous,
.react-datepicker__navigation--years-upcoming {
  height: 1.75rem;
}

.react-datepicker__navigation--years-previous {
  top: 0;
}

.react-datepicker__navigation--years-previous::after,
.react-datepicker__navigation--years-upcoming::after {
  content: '⌄';
  border-bottom: 0;
  text-indent: 0;
  font-size: 1rem;
  display: block;
}

.react-datepicker__navigation--years-upcoming::after {
  transform: rotate(180deg);
}

.react-datepicker-popper[data-placement^='top'] {
  padding-bottom: 1.25rem;
}
