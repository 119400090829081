.firebaseui-container {
  box-shadow: none !important;
  margin: 0 !important;
  max-width: unset !important;
  font-family: Poppins, sans-serif !important;
}

.firebaseui-text:last-child {
  margin-bottom: 1rem !important;
}

.firebaseui-card-header {
  display: none;
}

.firebaseui-link {
  color: #000000 !important;
}

.mdl-textfield__input {
  font-family: Poppins, sans-serif !important;
}

.firebaseui-form-actions {
  text-align: center !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #c2c2c2 !important;
}

.mdl-button {
  font-family: Poppins, sans-serif !important;
  border-radius: 0.375rem !important;
}

.mdl-button.mdl-button--colored {
  background: #ff002e !important;
}

.mdl-button--primary.mdl-button--primary {
  color: #ff002e !important;
}

.mdl-button.firebaseui-idp-password {
  background: #ff002e !important;
}
